import './App.css';
import RouteInfo from './resources/RouteInfo.json';
import Conzones from './resources/Conzones.json';
import { useState, useEffect } from 'react';
import useQuery from "./useQuery";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import * as Icon from './components/MuiIcon';
import Button from './components/Button';
import LabelInput from './components/LabelInput';
import Table from './components/Table';
import Folder from './components/folder';
import LabelSelect from './components/LabelSelect';
import Gauge from './components/Gauge';
import DeckMap from './components/Map';
import { dateToString, secondsToString, shortenBytes } from './utils';

function TimeDiff({ time }) {
  const [currentTime, setCurrentTime] = useState(Date.now());
  useEffect(() => {
    const handle = setInterval(() => setCurrentTime(Date.now()), 1000);
    return () => clearInterval(handle);
  }, []);
  const diff = Math.max(currentTime - time, 0);
  return secondsToString(diff/1000);
}

function Main() {
  const [columns, setColumns] = useState([]);
  const [checkboxTree, setCheckboxTree] = useState({});
  const [selection, setSelection] = useQuery();
  const [files, setFiles] = useState([]);
  const [worker, setWorker] = useState({});
  const [space, setSpace] = useState({free: 1, total: 1});
  const [unit, setUnit] = useState("100m");
  const [route, setRoute] = useState(RouteInfo[0].routeName);
  const [routeId, setRouteId] = useState(RouteInfo[0].routeId);
  const [directionCode, setDirectionCode] = useState('모두');
  const [queryDateBegin, setQueryDateBegin] = useState('2021-01-01');
  const [queryDateEnd, setQueryDateEnd] = useState('2021-01-01');
  const [conzoneEntry, setConzoneEntry] = useState(null);
  const [isSelectSection, setIsSelectSection] = useState(true);

  const query = Object.entries(selection).filter(x => x[1]).map(x => x[0]);
  const { isAlive, startDate } = worker;

  function goToCreatePage() {
    setIsSelectSection(true);
  }

  function goToTablePage() {
    setIsSelectSection(false);
  }

  function getCheckboxTree(columns) {
    const tree = {};
    for (const x of columns) {
      const key = x[0];
      const [t, ...rest] = x[1].slice(1, -1).split('.');

      const group = rest.length ? t : '기타';
      let title = rest.length ? rest.join('') : t;
      if (!tree[group]) {
        tree[group] = {};
      }

      if (title.includes(' - ')) {
        let subGroup;
        [subGroup, title] = title.split(' - ');
        if (!tree[group][subGroup]) {
          tree[group][subGroup] = {};
        }
        tree[group][subGroup][title] = key;
      } else {
        tree[group][title] = key;
      }
    }
    return tree;
  };

  function getCheckboxNode(title, node) {
    if (typeof(node) === 'number') { // Terminal Node
      const handleChange = (e) => setSelection({ ...selection, [node]: e.target.checked });
      
      return [(
        <li key={node} className="column-input">
          <input type="checkbox" checked={!!selection[node]} onChange={handleChange}></input>
          <label>{title.replace(/`/g, '')}</label>
        </li>
      ), [node]];
    } else { // Nonterminal Node
      const children = Object.entries(node).map(([key, child]) => getCheckboxNode(key, child));
      const components = children.map(child => child[0]);
      const keys = [].concat(...children.map(child => child[1]));
      const handleChange = (checked) => {
        const newSelection = { ...selection };
        keys.forEach(k => { newSelection[k] = checked; });
        setSelection(newSelection);
      };

      return [(
        <Folder
          title={title}
          checked={keys.map(k => !!selection[k]).reduce((s1, s2) => s1 && s2)}
          onCheckedChange={handleChange}
          key={title}>
          {components}
        </Folder>
      ), keys];
    }
  };

  useEffect(() => {
    setSelection({});
    (async () => {
      const columns = await (await fetch(`/api/columns/${unit}`)).json();
      setColumns(columns);
      setCheckboxTree(getCheckboxTree(columns));
    })();
  }, [unit]);

  async function refresh() {
    const [newWorker, newFiles] = await Promise.all([
      (await fetch('/api/worker')).json(),
      (await fetch('/api/file')).json()
    ]);
    setWorker(newWorker);
    setFiles(newFiles);
  }

  async function refreshSpace() {
    setSpace(await (await fetch("/api/space")).json())
  }

  useEffect(() => {
    refresh();
    refreshSpace();
    const handle = setInterval(refresh, 1000);
    return () => clearInterval(handle);
  }, []);

  async function handleStart() {
    if (query.length === 0) {
      alert('컬럼을 선택하세요.');
      return;
    }

    if (new Date(queryDateBegin) > new Date(queryDateEnd)) {
      alert(`시작 날짜(${queryDateBegin})가 끝 날짜(${queryDateEnd})보다 앞설 수 없습니다. 날짜를 확인해주세요.`);
      return;
    }

    await fetch('/api/worker', {
      method: 'post',
      body: JSON.stringify({
        unit: unit,
        selection: query,
        condition: {
          route: routeId,
          startDate: queryDateBegin.replaceAll('-', ''),
          endDate: queryDateEnd.replaceAll('-', ''),
          directionCode: (directionCode === "모두" ? null : directionCode),
          conzoneId: (conzoneEntry ? conzoneEntry.id : null)
        }
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    refresh();
  }

  async function handleStop() {
    await fetch('/api/worker', {
      method: 'delete',
    });
    refresh();
  }

  const handleDelete = (name, refresh=true) => async () => {
    await fetch(`/api/file/${name}`, {
      method: 'delete',
    });
    if (refresh) {
      refresh();
    }
  };

  const getFullSelection = (value) => () => setSelection(Object.fromEntries(columns.map((_, i) => [i, value])));

  const getButtonGroup = () => (
    <div className="button-group">
      <Button
        startIcon={<Icon.CheckBoxOutlinedIcon />}
        onClick={getFullSelection(true)}
      >
        모두 선택하기
      </Button>
      <Button
        startIcon={<Icon.IndeterminateCheckBoxOutlinedIcon />}
        onClick={getFullSelection(false)}
      >
        모두 해제하기
      </Button>
      <Button
        startIcon={<Icon.AddCircleOutlineOutlinedIcon />}
        onClick={handleStart}
        disabled={isAlive}
      >
        CSV 파일 생성하기
      </Button>
      <Button
        startIcon={<Icon.PauseCircleOutlineOutlinedIcon />}
        onClick={handleStop}
        disabled={!isAlive}
      >
        생성 중지하기
      </Button>
    </div>
  );

  const getInputGroup = () => (
    <div className="input-group">
      <LabelSelect
        label="매칭 단위"
        id="input-unit"
        entries={["100m", "500m", "1km", "VDS 구간"]}
        value={unit}
        onChange={setUnit}
      />
      <LabelSelect
        label="노선 이름"
        id="input-route"
        entries={RouteInfo.map(r => r.routeName).sort()}
        value={route}
        onChange={(name) => {
          setRoute(name);
          setRouteId(Number(RouteInfo.find(r => r.routeName === name).routeId));
        }}
      />
      <LabelSelect
        label="VDS 방향"
        id="input-direction"
        entries={["모두", "E", "S"]}
        value={directionCode}
        onChange={setDirectionCode}
      />
      <LabelInput
        label="시작 날짜"
        id="input-begin-date"
        type="date"
        value={queryDateBegin}
        onChange={setQueryDateBegin}
      />
      <LabelInput
        label="끝 날짜"
        id="input-end-date"
        type="date"
        value={queryDateEnd}
        onChange={setQueryDateEnd}
      />
      <Autocomplete
        id="conzone-autocomplete"
        sx={{ width: 350 }}
        options={Conzones}
        autoHighlight
        getOptionLabel={(option) => `${option.name} - ${option.id}`}
        renderInput={(params) => <TextField {...params} label="콘존 ID (비워둘 시 모두 선택)" />}
        value={conzoneEntry}
        onChange={(event, newValue) => setConzoneEntry(newValue)}
      />
    </div>
  );

  return (
    <div id="app">
      <header>
        <h1>교통안전 빅데이터 DB 뷰어 시스템</h1>
        <div id="logo-group">
          <img src="" alt="" />
          <img className="logo" src="img/한국도로공사_로고.png" alt="한국도로공사 로고" width="230px" />
          <img className="logo" src="img/한양대에리카_로고.png" alt="한양대 에리카" width="170px" />
          <img className="logo" src="img/서울시립대_로고.png" alt="서울시립대 로고" width="200px" />
        </div>
      </header>
      <nav>
        <div id="nav-gradient" />
        <img src="img/IC.png" alt="" />
        <div id="tap-group">
          <button onClick={goToCreatePage}>데이터 생성하기</button>
          <img className="divider" src="img/divider.png" alt="" />
          <button onClick={goToTablePage}>데이터 목록 보기</button>
          <img className="divider" src="img/divider.png" alt="" />
        </div>
      </nav>
      <div id="title-wrapper">
          <div id="title-gradient" />
          <img src="img/응봉산.png" alt="" />
          <div id="title-inner-wrapper">
            <div>{isSelectSection ? <h2>데이터 <br />생성하기</h2> : <h2>데이터 <br />목록 보기</h2>}</div>
          </div>
      </div>
      <main id="content">
        <div id="content-information">
            <p>{isSelectSection ? '옵션을 선택하여 CSV 데이터를 생성하세요' : '생성한 CSV 데이터를 다운로드하세요'}</p>
        </div>
        <div className="gauge-group">
          <Gauge name="현재 서버 저장 용량" total={space.total} current={space.total-space.free}>
            <p>
              <b>{shortenBytes(space.total)}</b>&nbsp;중&nbsp;<b>{shortenBytes(space.total-space.free)}</b>&nbsp;사용
            </p>
          </Gauge>
          <Gauge name="현재 생성 중인 데이터" total={worker.totalDays ? worker.totalDays : 1} current={worker.processedDays ? worker.processedDays : 1}>
            <p>
              {worker.totalDays ? (
                <><b>{worker.totalDays}</b>&nbsp;일 중&nbsp;<b>{worker.processedDays}</b>&nbsp;일 산출 완료 {`(실행 시간: `}<TimeDiff time={startDate}/>{`)`} <CircularProgress size={20} /></>
              ) : (
                <>산출이 완료되었습니다.</>
              )}
            </p>
          </Gauge>
        </div>
        {isSelectSection ? (
          <section id="select-section">
            <div className="select-content">
              {getButtonGroup()}
              {getInputGroup()}
              <div className="folder-group">
                {Object.entries(checkboxTree).map(([title, group]) => getCheckboxNode(title, group)[0])}
              </div>
            </div>
            <div className="map-wrapper">
              <DeckMap routeId={routeId} />
            </div>
          </section>
        ) : (
          <section id="file-section">
            {files.length ? (
              <div>
                <Button
                  id="delete-file-button"
                  onClick={async () => {
                    if (window.confirm('모든 CSV 파일을 삭제하시겠습니까?')) {
                      const jobs = files.map((file) => handleDelete(file.name, false)());
                      await Promise.all(jobs);
                      refresh();
                    }
                  }}
                >
                  전체 삭제하기
                </Button>
                <Table
                  header={['생성일시', '파일', '크기', '']}
                  rows={files.map((x) => [
                    dateToString(x.createdAt),
                    <a href={`/api/static/${x.name}`}>{x.name}</a>,
                    shortenBytes(x.size),
                    <Button onClick={handleDelete(x.name)} startIcon={<Icon.DeleteIcon />}>
                      삭제하기
                    </Button>
                  ])}
                />
              </div>) : (
                <div id="no-file">현재 생성된 파일이 없습니다.</div>
              )
            }
          </section>
        )}
        <Button variant="text" className="flip-page-button" onClick={() => setIsSelectSection(x => !x)}>
          {isSelectSection ? "데이터 목록 페이지로 이동" : "데이터 생성 페이지로 이동"}
        </Button>
      </main>
      <footer>
        Copyright 2022 Korea Expressway Corporation All rights reserved.
      </footer>
    </div>
  );
}

function App() {
  return <BrowserRouter>
    <Routes>
      <Route path="/" element={<Main />}></Route>
    </Routes>
  </BrowserRouter>;
}

export default App;
