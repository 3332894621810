import './map.css'
import React, { useEffect, useState } from 'react';
import DeckGL from '@deck.gl/react';
import Map from 'react-map-gl';
import { LineLayer } from '@deck.gl/layers';
import routePath from "../../resources/RoutePath.json";

// Viewport settings
const INITIAL_VIEW_STATE = {
  longitude: 127.91669,
  latitude: 35.7853,
  zoom: 6.5,
  pitch: 0,
  bearing: 0
};

const DeckMap = ({ routeId }) => {
	const [layers, setLayers] = useState([]);

	useEffect(() => {
		if (routePath[routeId]) {
			setLayers([new LineLayer({
				id: "route-layer",
				data: routePath[routeId],
				pickable: true,
				getWidth: 4,
				getColor: [255, 0, 0, 255],
				getSourcePosition: d => d.from.coordinates,
				getTargetPosition: d => d.to.coordinates,
			})]);
		} else {
			setLayers([]);
		}
	}, [routeId]);

	return (
		<DeckGL
			className="deckgl-root"
			initialViewState={INITIAL_VIEW_STATE}
			controller={true}
			layers={layers}
		>
			<Map mapStyle="mapbox://styles/mapbox/light-v10" />
		</DeckGL>
	)
}

export default DeckMap;