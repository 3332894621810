import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

export {
	CheckBoxOutlinedIcon,
	IndeterminateCheckBoxOutlinedIcon,
	KeyboardArrowUpIcon,
	KeyboardArrowDownIcon,
	AddCircleOutlineOutlinedIcon,
	PauseCircleOutlineOutlinedIcon,
	DeleteIcon	
};