import './gauge.css';
import React from 'react';

const Gauge = ({ name, current, total, children }) => {
	const ratio = Math.min((current / total) * 100, 100);

	return (
		<div className="gauge">
			<div className="gauge-name">{name}</div>
			<div className="track">
				<div className="range" style={{ width: `${ratio}%`}} />
			</div>
			<div>{children}</div>
		</div>
	)
}

export default Gauge;