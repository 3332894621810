
import { useSearchParams } from "react-router-dom";

export default function useQuery() {
  const [searchParam, setSearchParam] = useSearchParams();
  const entries = searchParam.entries();
  const query = {};
  for (let [key, value] of entries) {
    query[key] = JSON.parse(value);
  }
  const setQuery = (value) => setSearchParam(Object.fromEntries(Object.entries(value).map(([key, value]) => [key, JSON.stringify(value)])));

  return [query, setQuery];
};