export function dateToString(date) {
	if (typeof date === "string") {
			// date가 "2021-01-13T18:35:10.470Z"와 같은 문자열로 들어오는 경우를 대비하여,
			// date의 타입이 string이라면 그 문자열로 Date를 생성하여 아래 과정을 진행함.
			date = new Date(date);
	}

	if (date === undefined) {
			return "-";
	}

	let dateStr = date.getFullYear() + "년 " 
							+ (date.getMonth()+1 <= 9 ? "0" : "") + (date.getMonth()+1) + "월 "
							+ (date.getDate() <= 9 ? "0" : "") + date.getDate() + "일 " 
							+ (date.getHours() <= 9 ? "0" : "") + date.getHours() + ":"
							+ (date.getMinutes() <= 9 ? "0" : "") + date.getMinutes();
	return dateStr;
}

export function shortenBytes(bytes) {
    let reduced = 0;
    const units = ["B", "KB", "MB", "GB", "TB"];
    while (reduced+1 < units.length && bytes > 1024) {
      bytes /= 1024;
      reduced++;
    }
    return `${bytes.toFixed(2)} ${units[reduced]}`;
}

export function secondsToString(seconds) {
	seconds = Math.floor(seconds);
	const buckets = [];
	buckets.push(Math.floor(seconds / 3600));
	buckets.push(Math.floor(seconds / 60) % 60);
	buckets.push(seconds % 60);
	const unit = ["시간", "분", "초"];
	const result = buckets.map((v, i) => {
		if (v > 0) {
			return `${v}${unit[i]}`;
		} else {
			return null;
		}
	}).filter((x) => x !== null);

	if (result.length === 0) {
		return "0초";
	} else {
		return result.join(" ");
	}
}