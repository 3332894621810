import React from 'react';
import './labelInput.css';

const LabelInput = ({id, label, type, value, onChange}) => {
	return (
		<div className="label-input-container">
			<label htmlFor={id}>{label}</label>
			<input id={id} type={type} value={value} onChange={(e) => onChange(e.target.value)}/>
		</div>
	);
};

export default LabelInput;
