import React from 'react';
import './table.css';

const Table = ({ header, rows }) => {
	return (
		<table>
			<thead>
				<tr>
					{header.map((th) => <th key={th}>{th}</th>)}
				</tr>
			</thead>
			<tbody>
				{rows.map((row, i) =>
					<tr className={i % 2 == 0 ? 'odd' : 'even'} key={row[0]}>
						{row.map((r, j) =>
							<td key={`${r}${j}`}>{r}</td>)
						}
					</tr>
				)}
			</tbody>
		</table>
	);
};

export default Table;
