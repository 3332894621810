import React, { useState } from 'react';
import './folder.css'
import { Collapse } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const Folder = ({ title, checked, onCheckedChange, children, ...rest }) => {
	const [open, setOpen] = useState(false);

	const handleChange = (e) => {
		if (onCheckedChange) {
			onCheckedChange(e.target.checked);
		}
	}

	return (
		<div {...rest} className="folder">
			<button onClick={() => setOpen(!open)}>
				{!open ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
				<input type="checkbox" checked={checked} onChange={handleChange} onClick={e => e.stopPropagation()}></input>
				{title}
			</button>
			<Collapse in={open}>
				<div className="folder-content">
					{children}
				</div>
			</Collapse>
		</div>
	);
};

export default Folder;
