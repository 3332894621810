import React from 'react';
import './labelSelect.css';

const LabelSelect = ({id, label, entries, value, onChange}) => {
	return (
		<div className="label-input-container">
			<label htmlFor={id}>{label}</label>
			<select id={id} onChange={(e) => onChange(e.target.value)}>
                {entries.map(ent => 
                    <option
                        value={ent}
                        selected={value===ent}
                    >{ent}</option>
                )}
            </select>
		</div>
	);
};

export default LabelSelect;
